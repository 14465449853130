<script setup lang="ts">
defineProps({
	text: {
		type: String,
		default: 'filter.button.confirm'
	}
})

const { t: tg } = useI18n({
	useScope: 'global'
})

const filterStore = useFilterStore()
</script>

<template>
	<div class="flex gap-2 w-full">
		<div class="flex items-center w-[20px]">
			<UIcon
				v-show="filterStore.getPending ? filterStore.getPending() : false"
				name="i-svg-spinners-ring-resize"
				dynamic
			/>
		</div>
		<div class="w-full whitespace-nowrap">
			{{ tg(text, { count: filterStore.getCount ? filterStore.getCount() : 0 }) }}
		</div>
		<div class="w-[20px]"></div>
	</div>
</template>